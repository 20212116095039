<div class="container" [ngStyle]="{'width': appServiceScreenDesktop ? '80%' : '100%'}">
    <app-loading *ngIf="isLoading; else hideLoading"></app-loading>

    <ng-template #hideLoading>
        <div [ngClass]="{'row': appServiceScreenDesktop}" [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '80px' : '110px'}">
            <div [ngClass]="{'col-8': appServiceScreenDesktop}">
                
                <div [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '20px' : '30px'}">
                    <p [ngStyle]="{'font-size': appServiceScreenMobile ? '16px' : '24px', 'margin-bottom': appServiceScreenMobileSmall ? '20px' : '30px'}" style="color: #222222;font-weight: 700;">{{'Recipient_Address' | translate}}</p>
                    <div [ngStyle]="{'padding': appServiceScreenMobileSmall ? '10px' : '15px'}" style="display: flex;justify-content: space-between;align-items: center;background-color: white;margin-top: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;">
                        <div style="display: flex;flex-direction: column;">
                            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 700;"><i style="color: #0e8667;margin-right: 5px;" class="bi bi-person-badge"></i> {{'Recipient' | translate}}</label>
                            <label *ngIf="data.recipient_and_address.recipient != null" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;">{{data.recipient_and_address.recipient.name}}</label>
                            <label *ngIf="data.recipient_and_address.recipient != null" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;">{{data.recipient_and_address.recipient.phone_number}}</label>
                            <label *ngIf="data.recipient_and_address.recipient == null" [ngStyle]="{'font-size': appServiceScreenMobile ? '12px' : appServiceScreenMobileSmall ? '10px' : '14px'}" style="font-weight: 400;">{{'Recipients_have_not_been_selected' | translate}}</label>
                        </div>
                        <button (click)="callRecipient()" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '14px', 'width': data.recipient_and_address.recipient == null ? '21%' : ''}" type="button" style="height: 36px;border-radius: 8px;background-color: white;font-weight: 700;color: #0e8667;border: 2px solid #DDDDDD;" mat-button>
                            <i *ngIf="data.recipient_and_address.recipient != null" [ngStyle]="{'margin-right': appServiceScreenDesktop ? '5px' : '0px'}" style="color: #0e8667;font-size: 14px;" class="bi bi-pencil-square"></i> 
                            <mat-label *ngIf="appServiceScreenDesktop && data.recipient_and_address.recipient != null">{{'Edit' | translate}}</mat-label>
                            <mat-label *ngIf="data.recipient_and_address.recipient == null">{{'Select_recipient' | translate}}</mat-label>
                        </button>
                    </div>

                    <div [ngStyle]="{'padding': appServiceScreenMobileSmall ? '10px' : '15px'}" style="display: flex;justify-content: space-between;align-items: center;background-color: white;margin-top: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;">
                        <div style="display: flex;flex-direction: column;">
                            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 700;"><i style="color: #0e8667;margin-right: 5px;" class="bi bi-geo-alt"></i> {{'Address' | translate}}</label>
                            <label *ngIf="data.recipient_and_address.address != null" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '16px'}" style="font-weight: 700;margin-right: 5px;">{{data.recipient_and_address.address.address}}, {{data.recipient_and_address.address.province}}, {{data.recipient_and_address.address.city}}, {{data.recipient_and_address.address.postal_code}}</label>
                            <label *ngIf="data.recipient_and_address.address == null" [ngStyle]="{'font-size': appServiceScreenMobile ? '12px' : appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;">{{'Address_has_not_been_selected' | translate}}</label>
                        </div>
                        <button (click)="callAddress()" type="button" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '14px', 'width': data.recipient_and_address.address == null ? '21%' : ''}" style="height: 36px;border-radius: 8px;background-color: white;font-weight: 700;color: #0e8667;border: 2px solid #DDDDDD;" mat-button>
                            <i *ngIf="data.recipient_and_address.address != null" [ngStyle]="{'margin-right': appServiceScreenDesktop ? '5px' : '0px'}" style="color: #0e8667;font-size: 14px;" class="bi bi-pencil-square"></i> 
                            <mat-label *ngIf="appServiceScreenDesktop && data.recipient_and_address.address != null">{{'Edit' | translate}}</mat-label>
                            <mat-label *ngIf="data.recipient_and_address.address == null">{{'Select_address' | translate}}</mat-label>
                        </button>
                    </div>
                </div>

                <p [ngStyle]="{'font-size': appServiceScreenMobile ? '16px' : '24px', 'margin-top': appServiceScreenMobileSmall ? '20px' : '30px'}" style="color: #222222;font-weight: 700;">{{'My_Cart' | translate}}</p>
                <div *ngIf="data.my_cart.length > 0; else noCart;">
                    <div *ngFor="let row of dataCart;let indexArray = index;" style="background-color: white;margin-top: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;">
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div style="display: flex;margin: 20px 0px 10px 0px;align-items: center;padding: 0px 15px;">
                                <div style="margin-right: 20px;">
                                    <img alt="chatfoto" ngSrc="{{row.image_icon_url}}" style="border-radius: 4px;" width="48px" height="48px">
                                </div>
                                <div style="display: flex;flex-direction: column;">
                                    <label [ngStyle]="{'font-size': appServiceScreenMobile ? '14px' : '16px'}" style="color: #222222;font-weight: 700;">{{row.name}}</label>
                                    <label [ngStyle]="{'font-size': appServiceScreenMobile ? '14px' : '16px'}" style="color: #0e8667;font-weight: 700;">{{(row.price| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</label>
                                </div>
                            </div>
                            <div style="display: flex;">
                                <!-- <button (click)="callDeleted(row.photo_size_id, 'cartPhotoAll', 'deleted_all', row.name)" [ngStyle]="{'margin': appServiceScreenDesktop ? '0px 10px' : '0px'}" type="button" style="height: 36px;border-radius: 8px;background-color: white;font-size: 14px;font-weight: 700;color: #b8505b;border: 2px solid #DDDDDD;" mat-button>
                                    <i [ngStyle]="{'margin-right': appServiceScreenDesktop ? '5px' : '0px'}" style="color: #b8505b;" class="bi bi-trash"></i> 
                                    <mat-label *ngIf="appServiceScreenDesktop">{{'Delete_all' | translate}}</mat-label>
                                </button> -->
                                <button (click)="appService.reloadPage(false, 'photo/'+row.photo_size_id+'/false/true/true')" type="button" style="height: 36px;border-radius: 8px;background-color: white;font-size: 14px;font-weight: 700;color: #0e8667;border: 2px solid #DDDDDD;margin: 0px 9px;" mat-button>
                                    <!-- <i [ngStyle]="{'margin-right': appServiceScreenDesktop ? '5px' : '0px'}" style="color: #0e8667;" class="bi bi-pencil-square"></i>  -->
                                    <img alt="chatfoto" [ngStyle]="{'margin-right': appServiceScreenDesktop ? '5px' : '0px'}" src="../../../../assets/img/review-photos.png" style="color: #0e8667;" width="24px" height="24px">
                                    <mat-label *ngIf="appServiceScreenDesktop">{{'review_photos' | translate}}</mat-label>
                                </button>
                            </div>
                        </div>
                        <hr>
                        <div>
                            <div *ngFor="let rowPhoto of row.photos;let iArr = index" style="display: flex;margin: 20px 0px 10px 0px;align-items: center;padding: 0px 15px;overflow: hidden;text-overflow: ellipsis;">
                                <div style="margin-right: 20px;width: 80px;height: 80px;text-align: center;align-content: center;">
                                    <img alt="chatfoto" [ngStyle]="{'padding': rowPhoto.white_border == 1 ? '0.25rem' : '', 'border': rowPhoto.white_border == 1 ? '1px solid #dee2e6' : '', 'height': rowPhoto.orientation == 'LANDSCAPE' ? '' : '100%', 'width': rowPhoto.orientation == 'LANDSCAPE' ? '100%' : ''}" src="{{rowPhoto.file_name_thumbnail}}" style="background-position: center;background-size: contain;background-repeat: no-repeat;">
                                </div>
                                <div style="width: 100%;overflow: hidden;text-overflow: ellipsis;">
                                    <label [ngStyle]="{'white-space': appServiceScreenMobile ? 'nowrap' : '','width': appServiceScreenMobile ? '190px' : '','overflow': appServiceScreenMobile ? 'hidden' : '','text-overflow': appServiceScreenMobile ? 'ellipsis' : ''}" style="color: #222222;font-weight: 400;font-size: 14px;">Photo {{iArr + 1}} 
                                        <!-- <span *ngIf="rowPhoto.white_border == 1" style="font-weight: 400;font-size: 14px;">white border</span> -->
                                    </label>
                                    <div [ngStyle]="{'flex-direction': appServiceScreenDesktop ? '' : 'column', 'align-items': appServiceScreenDesktop ? 'flex-end' : 'flex-start'}" style="display: flex;justify-content: space-between;">
                                        <div [ngStyle]="{'margin-bottom': appServiceScreenDesktop ? '' : '6px'}" style="display: grid;">
                                            <label style="color: #0e8667;font-weight: 700;font-size: 16px;">{{(rowPhoto.price| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</label>
                                            <mat-slide-toggle [(ngModel)]="rowPhoto.white_border" (change)="selectWhiteBorder(rowPhoto.photo_id,$event)">White border</mat-slide-toggle>
                                        </div>
                                        <div style="display: flex;align-items: center;" [ngStyle]="{'margin-top': appServiceScreenMobile ? '8px' : '0px'}">
                                            <i matRipple (click)="callDeleted(rowPhoto.photo_id, 'photoById', 'deleted_pc')" style="cursor: pointer;border: 2px solid #DDDDDD;border-radius: 8px;padding: 0px 5px;color: #b8505b;margin-right: 10px;" class="bi bi-trash"></i>
                                            <!-- <i matRipple (click)="appService.reloadPage(false, 'editor/'+rowPhoto.photo_id+'/true/cart')" style="cursor: pointer;;color: #0e8667;border: 2px solid #DDDDDD;border-radius: 8px;padding: 0px 5px;margin-right: 10px;" class="bi bi-pencil-square"></i> -->
                                            <i matRipple (click)="rowPhoto.white_border == 1 ? '' : appService.reloadPage(false, 'editor/'+rowPhoto.photo_id+'/true/cart')" [ngStyle]="{'cursor': rowPhoto.white_border == 1 ? 'none' : 'pointer', 'color': rowPhoto.white_border == 1 ? '#DDDDDD' : '#0e8667'}" style="border: 2px solid #DDDDDD;border-radius: 8px;padding: 0px 5px;margin-right: 10px;" class="bi bi-pencil-square"></i>
                                            <label (click)="addQtyCart(rowPhoto.cart_id ,rowPhoto.qty - 1, rowPhoto.photo_id, 'photoById')" matRipple style="cursor: pointer;padding: 0px 5px;border-top: 2px solid #DDDDDD;border-bottom: 2px solid #DDDDDD;border-left: 2px solid #DDDDDD;border-top-left-radius: 8px;border-bottom-left-radius: 8px;"><i style="color: #222222;" class="bi bi-dash-lg"></i></label>
                                            <label style="padding: 0px 15px;border-top: 2px solid #DDDDDD;border-bottom: 2px solid #DDDDDD;">{{rowPhoto.qty}}</label>
                                            <label (click)="addQtyCart(rowPhoto.cart_id ,rowPhoto.qty + 1, rowPhoto.photo_id, 'photoById')" matRipple style="cursor: pointer;border-top: 2px solid #DDDDDD;border-bottom: 2px solid #DDDDDD;border-right: 2px solid #DDDDDD;border-top-right-radius: 8px;border-bottom-right-radius: 8px;padding: 0px 5px;"><i style="color: #222222;" class="bi bi-plus"></i></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div style="display: flex;justify-content: space-between;align-items: center;padding: 0px 15px 15px;">
                            <label [ngStyle]="{'font-size': appServiceScreenMobile ? '14px' : '16px'}" style="color: #222222;font-weight: 700;margin-right: 10px;width: 70%;">{{'Want_to_add_additional_pictures' | translate : {name: row.name} }} </label>
                            <input style="display: none" multiple type="file" accept="image/png, image/jpg, image/jpeg, image/webp" (change)="selectFile($event, row.photo_size_id)" #hiddenfileinput>
                            <div (click)="hiddenfileinput.click()" matRipple [ngStyle]="{'height': appServiceScreenMobileSmall ? '50px' : 'auto', 'width': appServiceScreenMobile || appServiceScreenMobileSmall ? '110px' : ''}" style="border: 1px dashed #DDDDDD;border-radius: 4px;cursor: pointer;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                                <i class="bi bi-plus-circle-fill" style="color: #0e8667;"></i>
                                <label [ngStyle]="{'padding': appServiceScreenDesktop ? '0 10px' : ''}" style="font-size: small;color: #0e8667;text-align: center;">{{'Add_more' | translate}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #noCart>
                    <div style="text-align: center;">
                        <img alt="chatfoto" ngSrc="../../../../assets/img/notfound.webp" width="192" height="150">
                        <p [ngStyle]="{'font-size': appServiceScreenMobile ? '18px' : '20px'}" style="font-weight: 700;">{{'Your_cart_is_empty' | translate}}</p>
                        <p [ngStyle]="{'font-size': appServiceScreenMobile ? '14px' : '16px'}" style="font-weight: 400;color: #555555;">{{'text_empty_cart' | translate}}</p>
                        <button mat-button (click)="appService.reloadPage(false, 'print')" type="button" [ngStyle]="{'font-size': appServiceScreenMobile ? '14px' : '16px', 'margin': appServiceScreenMobileSmall ? '20px 0px 10px 0px' : '30px 0px 20px 0px'}" style="width: 100%;border-radius: 8px;background-color: #0e8667;font-weight: 700;color: white;">{{'Explore_products' | translate}}</button>
                    </div>
                </ng-template>

                <div *ngIf="dataCart.length >= 1 && dataCart.length < 3" style="display: flex;justify-content: space-between;align-items: center;padding: 15px;border: 1px solid #DDDDDD;border-radius: 8px;margin-top: 20px;background-color: white;">
                    <label [ngStyle]="{'font-size': appServiceScreenMobile ? '14px' : '16px'}" style="color: #222222;font-weight: 700;margin-right: 10px;width: 70%;">{{'Want_to_add_other_print_size' | translate}} </label>
                    <div (click)="appService.reloadPage(false, 'print')" matRipple [ngStyle]="{'height': appServiceScreenMobileSmall ? '50px' : 'auto', 'width': appServiceScreenMobile || appServiceScreenMobileSmall ? '110px' : ''}" style="border: 1px dashed #DDDDDD;border-radius: 4px;cursor: pointer;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                        <i class="bi bi-plus-circle-fill" style="color: #0e8667;"></i>
                        <label [ngStyle]="{'padding': appServiceScreenDesktop ? '0 10px' : ''}" style="font-size: small;color: #0e8667;text-align: center;">{{'Add_more' | translate}}</label>
                    </div>
                </div>
            </div>


            <div [ngClass]="{'col-4': appServiceScreenDesktop, 'col-sm': appServiceScreenMobile || appServiceScreenMobileSmall}" [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '25px' : '45px'}" style="margin-top: 45px;">
                <div [ngStyle]="{'width': appServiceScreenMobile ? '100%' : '358px','border': appServiceScreenMobile ? '' : '1px solid #DDDDDD'}" style="background-color: white;margin-top: 20px;border-radius: 12px;">
                    
                    <!-- USE DISCOUNT (ini nanti dihapus) -->
                    <div matRipple [ngStyle]="{'margin': appServiceScreenMobile ? '' : '15px'}" style="background-color: white;border: 1px solid #DDDDDD;border-radius: 8px;display: flex;justify-content: center;flex-direction: column;padding: 10px;">
                        <div (click)="callVoucher()" style="cursor: pointer;display: flex;justify-content: space-between;">
                            <label style="cursor: pointer;font-weight: 700;"><i style="color: #0e8667;margin-right: 5px;" class="bi bi-tag"></i> {{data.voucher == null ? ('Use_discount' | translate) : data.voucher.code}}</label>
                            <i class="bi bi-caret-right-fill"></i>
                        </div>
                        
                        <div *ngIf="data.voucher != null" style="display: flex;padding-top: 10px;">
                            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{data.voucher.description}} <u (click)="removeVoucher()" style="cursor: pointer;color: #0e8667;">{{'remove' | translate}}</u></label>
                        </div>
                    </div>


                    <!-- SHIPPING METHOD (ini nanti dihapus notenya) --> 
                    <hr *ngIf="appServiceScreenDesktop" style="height: 4px;color: #c4c4c4;">
                    <div matRipple (click)="!data.voucher || data.voucher.is_direct_print !== 1 ? callDelivery() : null" 
                                [ngStyle]="{
                                    'margin': appServiceScreenMobile ? '20px 0px 0px 0px' : '15px',
                                    'cursor': !data.voucher || data.voucher.is_direct_print !== 1 ? 'pointer' : 'not-allowed',
                                    'background-color': !data.voucher || data.voucher.is_direct_print !== 1 ? 'white' : '#f0f0f0'
                                }" 
                                style="border: 1px solid #DDDDDD; border-radius: 8px; display: flex; flex-direction: column; justify-content: center; padding: 10px;">

                        
                        <div style="cursor: pointer;display: flex;justify-content: space-between;">
                            <label style="cursor: pointer;font-weight: 700;"><i style="color: #0e8667;margin-right: 5px;" class="bi bi-truck"></i> {{'Shipping_method' | translate}}</label>
                            <i class="bi bi-caret-right-fill"></i>
                        </div>

                        <div *ngIf="data.delivery_method != null" style="display: flex;padding-top: 10px;">
                            <div style="border: 1px solid #DDDDDD;border-radius: 8px;padding: 10px;">
                                <img alt="chatfoto" src="{{data.delivery_method.image}}" width="56" height="22">
                            </div>
                            <div style="display: flex;flex-direction: column;align-items: start;padding: 0px 20px;">
                                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;">
                                    {{data.delivery_method.name}}
                                </label>
                                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '12px'}" style="font-weight: 400;color: #717171;">{{data.delivery_method.data.delivery_type}}&nbsp;|&nbsp;<label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #0e8667;font-weight: 400;">{{(data.delivery_method.data.tarif| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</label></label>
                            </div>
                        </div>
                        
                        <label *ngIf="data.delivery_method == null" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;cursor: pointer;">{{'Shipping_method_has_not_been_selected' | translate}}</label>
                    </div>

                    
                    <!-- PAYMENT METHOD -->
                    <hr *ngIf="appServiceScreenDesktop" style="height: 4px;color: #c4c4c4;">
                    <div matRipple (click)="!data.voucher || data.voucher.is_direct_print !== 1 ? callPaymentNew() : null" 
                                    [ngStyle]="{
                                        'margin': appServiceScreenMobile ? '20px 0px 0px 0px' : '15px',
                                        'cursor': !data.voucher || data.voucher.is_direct_print !== 1 ? 'pointer' : 'not-allowed',
                                        'background-color': !data.voucher || data.voucher.is_direct_print !== 1 ? 'white' : '#f0f0f0'
                                    }" 
                                    style="border: 1px solid #DDDDDD; border-radius: 8px; display: flex; flex-direction: column; justify-content: center; padding: 10px;">

                        
                        <div style="cursor: pointer;display: flex;justify-content: space-between;">
                            <label style="cursor: pointer;font-weight: 700;"><i style="color: #0e8667;margin-right: 5px;" class="bi bi-credit-card"></i> {{'Payment Method' | translate}}</label>
                            <i class="bi bi-caret-right-fill"></i>
                        </div>

                        <div *ngIf="data.payment_details != null" style="display: flex;padding-top: 10px;">
                            <div style="border: 1px solid #DDDDDD;border-radius: 8px;padding: 10px;">
                                <img alt="chatfoto" src="{{data.payment_details.image_url}}" width="56" height="22">
                            </div>
                            <div style="display: flex;flex-direction: column;align-items: start;padding: 0px 20px;">
                                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;">
                                    {{data.payment_details.name}}
                                </label>
                                <!-- <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '12px'}" style="font-weight: 400;color: #717171;">{{data.payment_details.data.delivery_type}}&nbsp;|&nbsp;<label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #0e8667;font-weight: 400;">{{(data.delivery_method.data.tarif| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</label></label> -->
                            </div>
                        </div>
                        
                        <label *ngIf="data.payment_details == null" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;cursor: pointer;">{{'Payment Gateway has not been selected' | translate}}</label>
                    </div>


                    <!-- SHOPPING SUMMARY SECTION -->
                    <hr *ngIf="appServiceScreenDesktop" style="height: 4px;color: #c4c4c4;">
                    <div [ngStyle]="{'padding-top': appServiceScreenMobile ? appServiceScreenMobileSmall ? '30px' : '50px' : '','margin': appServiceScreenMobile ? '5px' : '15px'}" style="margin: 15px;">
                        <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;color: #222222;">{{'Shopping_summary' | translate}}</p>
                        <div style="display: flex;justify-content: space-between;">
                            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Total_price' | translate}}</p>
                            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{(data.shopping_summary.print_cost| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</p>
                        </div>
                        <div style="display: flex;justify-content: space-between;">
                            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Shipping_costs' | translate}}</p>
                            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{(data.shopping_summary.delivery_cost| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</p>
                        </div>
                        <div style="display: flex;justify-content: space-between;">
                            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Total_discount' | translate}}</p>
                            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #e50000;">{{(data.shopping_summary.delivery_discount| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</p>
                        </div>
                        <hr>
                        
                        <div style="display: flex;justify-content: space-between;">
                            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;color: #222222;">{{'Total_bill' | translate}}</p>
                            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;color: #222222;">{{(data.shopping_summary.total| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</p>
                        </div>


                        <button (click)="callOrder()" [disabled]="!canProceedToPayment()"
                                [ngStyle]="{
                                    'background-color': canProceedToPayment() ? '#0e8667' : '#CCCCCC',
                                    'height': appServiceScreenMobileSmall ? '38px' : '48px',
                                    'font-size': appServiceScreenMobileSmall ? '14px' : '16px'
                                }"
                                style="width: 100%; border-radius: 8px; font-weight: 700; color: white;" 
                                
                                mat-button> {{'Select_payment' | translate}}
                        </button>

                    </div>

                </div>
            </div>


        </div>
    </ng-template>
</div>