import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient, private apiLogin: ApiLogin) { }

  public showPayment() {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.get(`${this.baseUrl}payment`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  // DI HIDE DULU KARNA BELUM TERPAKAI, INI HANYA BERGUNA UNTUK MENG-SET DATA PADA BOX-SHOW JIKA SUDAH DI KLIK
  public setPayment(paymentId: number) {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    const data = JSON.stringify({
        "payment_id": paymentId

        // "delivery_id": deliveryId,
        // "delivery_type": deliveryType
      })
    return this.httpClient.post<any>(`${this.baseUrl}select-payment`, data, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

}
