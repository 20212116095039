import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ParentComponent } from './components/pages/parent/parent.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgImageSliderModule } from 'ng-image-slider';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularMaterialModule } from './angular-material.module';
import { AppService } from './app.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { PrintComponent } from './components/pages/print/print.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { MyOrderComponent } from './components/pages/my-order/my-order.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { GalleryComponent } from './components/pages/home/partials/gallery/gallery.component';
import { HighlightProductComponent } from './components/pages/home/partials/highlight-product/highlight-product.component';
import { WhyComponent } from './components/pages/home/partials/why/why.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { RecipientComponent } from './components/pages/dialog/recipient/recipient.component';
import { AddressComponent } from './components/pages/dialog/address/address.component';
import { DeliveryComponent } from './components/pages/dialog/delivery/delivery.component';
import { OrderConfirmationComponent } from './components/pages/dialog/order-confirmation/order-confirmation.component';
import { PaymentMethodComponent } from './components/pages/dialog/payment-method/payment-method.component';
import { SuccessComponent } from './components/pages/my-order/success/success.component';
import { VoucherComponent } from './components/pages/dialog/voucher/voucher.component';
import { DetailComponent } from './components/pages/dialog/voucher/detail/detail.component';
import { DetailTransactionComponent } from './components/pages/dialog/detail-transaction/detail-transaction.component';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from './components/pages/dialog/login/login.component';
import { UploadPhotoComponent } from './components/pages/photo/upload-photo/upload-photo.component';
import { DeletedComponent } from './components/pages/dialog/deleted/deleted.component';
import { PrintButtonMobileComponent } from './components/layouts/header/print-button-mobile/print-button-mobile.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingComponent } from './components/loading/loading.component';
import { RegistrationComponent } from './components/pages/dialog/registration/registration.component';
import { ForgetPasswordComponent } from './components/pages/dialog/forget-password/forget-password.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { PhotoComponent } from './components/pages/photo/photo.component';
import { ListPhotoComponent } from './components/pages/photo/list-photo/list-photo.component';
import { NextPreviousScrollableDirective } from './components/pages/photo/list-photo/next-previous-scrollable.directive';
import { ProgressBarComponent } from './components/pages/photo/progress-bar/progress-bar.component';
import { HeaderPhotoComponent } from './components/pages/photo/header-photo/header-photo.component';
import { LoadingDialogComponent } from './components/loading/loading-dialog/loading-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DatePipe, NgOptimizedImage } from '@angular/common';
import { Faq2Component } from './components/pages/faq/faq2/faq2.component';
import { Faq3Component } from './components/pages/faq/faq3/faq3.component';
import { Faq4Component } from './components/pages/faq/faq4/faq4.component';
import { Faq5Component } from './components/pages/faq/faq5/faq5.component';
import { Faq6Component } from './components/pages/faq/faq6/faq6.component';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { EditorComponent } from './components/pages/photo/editor/editor.component';
import { FailedComponent } from './components/pages/my-order/failed/failed.component';
import { UnsuccessComponent } from './components/pages/my-order/unsuccess/unsuccess.component';
import { ListComponent } from './components/pages/my-order/list/list.component';
import { BadgetQtyComponent } from './components/layouts/header/badget-qty/badget-qty.component';
import { AccountComponent } from './components/pages/account/account.component';
import { TncComponent } from './components/pages/dialog/tnc/tnc.component';
import { DetailMyOrderComponent } from './components/pages/dialog/detail-my-order/detail-my-order.component';
import { EmailVerifyComponent } from './components/pages/email-verify/email-verify.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { ProgressbarDialogComponent } from './components/loading/progressbar-dialog/progressbar-dialog.component';
import { TestimonialComponent } from './components/pages/home/partials/testimonial/testimonial.component';
import { Faq1Component } from './components/pages/faq/faq1/faq1.component';
import { ImagePromotionComponent } from './components/pages/dialog/image-promotion/image-promotion.component';
import { MessageComponent } from './components/pages/dialog/message/message.component';
import { ButtonClosePromotionComponent } from './components/pages/dialog/button-close-promotion/button-close-promotion.component';
import { ButtonOrderComponent } from './components/pages/dialog/button-order/button-order.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { Router } from '@angular/router';
import { PaymentNewComponent } from './components/pages/dialog/payment-new/payment-new.component';

@NgModule({
  declarations: [
    AppComponent,
    ParentComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    PrintComponent,
    ContactComponent,
    MyOrderComponent,
    CartComponent,
    GalleryComponent,
    HighlightProductComponent,
    WhyComponent,
    FaqComponent,
    SidebarComponent,
    RecipientComponent,
    AddressComponent,
    DeliveryComponent,
    OrderConfirmationComponent,
    PaymentMethodComponent,
    SuccessComponent,
    VoucherComponent,
    DetailComponent,
    DetailTransactionComponent,
    LoginComponent,
    UploadPhotoComponent,
    DeletedComponent,
    PrintButtonMobileComponent,
    LoadingComponent,
    RegistrationComponent,
    ForgetPasswordComponent,
    PhotoComponent,
    ListPhotoComponent,
    NextPreviousScrollableDirective,
    ProgressBarComponent,
    HeaderPhotoComponent,
    LoadingDialogComponent,
    Faq2Component,
    Faq3Component,
    Faq4Component,
    Faq5Component,
    Faq6Component,
    EditorComponent,
    FailedComponent,
    UnsuccessComponent,
    ListComponent,
    BadgetQtyComponent,
    AccountComponent,
    TncComponent,
    DetailMyOrderComponent,
    EmailVerifyComponent,
    ResetPasswordComponent,
    ProgressbarDialogComponent,
    TestimonialComponent,
    Faq1Component,
    ImagePromotionComponent,
    MessageComponent,
    ButtonClosePromotionComponent,
    ButtonOrderComponent,
    PrivacyPolicyComponent,
    PaymentNewComponent
  ],
  imports: [
    AngularPinturaModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgImageSliderModule,
    AngularMaterialModule,
    NgxMatSelectSearchModule,
    NgOptimizedImage,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [AppService, DatePipe,
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {}, multi: true },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {}, multi: true },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {} }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { 
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}