import { Component, Inject, Injector } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { PaymentModel } from 'src/app/models/payment-new-model';
import { ApiService as ApiPayment } from 'src/app/services/payment-new/api.service';

@Component({
  selector: 'app-payment-new',
  templateUrl: './payment-new.component.html',
  styleUrls: ['./payment-new.component.css']
})

export class PaymentNewComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  isLoading: boolean = true;
  dataModel!: PaymentModel.Result[];
  addressId!: number;
  setPayment!: PaymentModel.Result;
  // setDeliveryTarif!: PaymentModel.Tarif;

  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<PaymentNewComponent>,
    private bottomSheetRef: MatBottomSheetRef<PaymentNewComponent>,
    private ApiPayment: ApiPayment,
    private snackBar: MatSnackBar,
    public appService: AppService
  ) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA);
    
      this.addressId = this.data == undefined ? '' : this.data['addressId']
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA);
    
      this.addressId = this.dataMobile == undefined ? '' : this.dataMobile['addressId']
    }

    this.showData(this.addressId)
  }

  showData(addressId: number) {
    this.isLoading = false;
    
    this.ApiPayment.showPayment()
    .subscribe({
      next: (response: any) => {
        this.isLoading = true
        this.dataModel = response.results
      },
      error: (error) => {
        this.isLoading = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  async setRecipientTemp() {
    this.isLoading = false;
    
    (this.ApiPayment.setPayment(this.setPayment.id))
    .subscribe({
      next: (response) => {
        this.isLoading = true

        if(this.appServiceScreenMobile) {
          this.bottomSheetRef.dismiss()
        } else {
          this.dialogRef.close()
        }

        // this.appService.reloadPage(true)
      },
      error: (error) => {
        this.isLoading = true
      },
    })
  }

  selectedPayment(data: PaymentModel.Result) {
    this.setPayment = data
    // this.setDeliveryTarif = tarif
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }


}
