<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '16px' : '20px'}" style="font-weight: 700;">{{'Select a Payment Method' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>

<div mat-dialog-content style="text-align: center;">
    <app-loading *ngIf="isLoading == false; else hideLoading"></app-loading>
    <ng-template #hideLoading>
        <div *ngFor="let row of dataModel;let indexArr = index;">
            <!-- <div *ngFor="let rowTarif of row.data.tarif;let indexTarif = index;" [ngStyle]="{'padding': appServiceScreenMobileSmall ? '10px' : '15px'}" style="display: flex;justify-content: space-between;align-items: center;background-color: white;margin-bottom: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;"> -->
                <div style="border: 1px solid #DDDDDD;border-radius: 8px;padding: 10px;">
                    <img alt="chatfoto" ngSrc="{{row.image_url}}" width="56" height="22">
                </div>
                <div style="display: flex;flex-direction: column;align-items: start;padding: 0px 20px 0px 0px;">
                    <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;">
                        {{row.name}}
                    </label>
                    <!-- <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '12px'}" style="text-align: left;font-weight: 400;color: #717171;">{{rowTarif.delivery_type}}&nbsp;|&nbsp;<label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #0e8667;font-weight: 400;">{{(rowTarif.tarif| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</label></label> -->
                </div>
                <mat-radio-button (change)="selectedPayment(dataModel[indexArr])"></mat-radio-button>
            <!-- </div> -->
        </div>
    </ng-template>
</div>

<mat-dialog-actions [ngStyle]="{'display': appServiceScreenMobile ? 'flex' : '', 'align-items': appServiceScreenMobile ? 'center' : ''}">
    <div class="col-6" [ngStyle]="{'padding-left': appServiceScreenMobile ? '0px' : '20px'}">
        <!-- <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '12px'}" style="font-weight: 400;color: #717171;">{{'Shipping_cost' | translate}}</label>
        <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;color: #0e8667;">{{((setDelivery != undefined ? setDeliveryTarif.tarif : 0) | currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</p> -->
    </div>
    <div class="col-6" [ngStyle]="{'padding-right': appServiceScreenMobile ? '0px' : '20px'}" style="text-align: end;">
        <button (click)="setRecipientTemp()" disabled="{{setPayment != undefined ? false : true}}" [ngStyle]="{'background-color': setPayment != undefined ? '#0e8667' : '#DDDDDD', 'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="width: 100%;border-radius: 8px;font-weight: 700;color: white;" mat-button>{{'Continue' | translate}}</button>
    </div>
</mat-dialog-actions>
